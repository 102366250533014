import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { FormArray, FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { BackboneComponent } from '@components/backbone/backbone.component';
import { BreadcrumbComponent } from '@components/breadcrumb/breadcrumb.component';
import { ToastService } from '@utils/toast.service';
import { CatalogService } from '@services/catalog.service';
import { RealEstateService } from '@services/realestate.service';
import { requiredFileSize, requiredFileType } from '@utils/validators';
import { InputTextModule } from 'primeng/inputtext';
import { DropdownModule } from 'primeng/dropdown'
import { InputNumberModule } from 'primeng/inputnumber';
import { PreviewUploadImageComponent } from '@components/preview-upload-image/preview-upload-image.component';
import { ButtonModule } from 'primeng/button';
import { EditorComponent, TINYMCE_SCRIPT_SRC } from '@tinymce/tinymce-angular';
import { GoogleMapsModule, MapAdvancedMarker } from '@angular/google-maps';
import { AutoCompleteModule } from 'primeng/autocomplete'

interface AutoCompleteCompleteEvent {
  originalEvent: Event
  query: string
}

@Component({
  selector: 'app-realestate-create',
  standalone: true,
  imports: [
    BackboneComponent,
    BreadcrumbComponent,
    ReactiveFormsModule,
    CommonModule,
    InputTextModule,
    DropdownModule,
    InputNumberModule,
    PreviewUploadImageComponent,
    ButtonModule,
    EditorComponent,
    GoogleMapsModule,
    MapAdvancedMarker,
    AutoCompleteModule
  ],
  providers: [
    ToastService,
    { provide: TINYMCE_SCRIPT_SRC, useValue: 'tinymce/tinymce.min.js' }
  ],
  templateUrl: './create.component.html',
  styleUrl: './create.component.scss'
})
export class RealEstateCreatePage {
  private formBuilder = inject(FormBuilder);
  private toastService = inject(ToastService);
  private router = inject(Router);
  private catalogService = inject(CatalogService);
  private realEstateService = inject(RealEstateService);

  public isLoading = false;
  public isSaving = false;

  public typeProperties: any[] = []
  public statusProperties: any[] = []
  public states: any[] = []
  public addressSuggestions: any[] = []
  public municipalities: any[] = []
  public currencies: any[] = [
    { value: 'USD', label: 'Dólares americanos' },
    { value: 'MXN', label: 'Pesos mexicanos' },
    { value: 'EUR', label: 'Euros' }
  ]
  public mapId = '2558f9905a1514c1';
  public mapOptions: google.maps.MapOptions = {
    disableDefaultUI: true,
    zoomControl: true,
    minZoom: 5,
    zoom: 5
  }
  public mapCenterLatLng = { lat: 24.4329327, lng: -102.0087475 }
  public mapCenter = this.mapCenterLatLng
  public mapZoom!: any
  public markerMap!: google.maps.LatLngLiteral
  public tinymceInit: EditorComponent['init'] = {
    plugins: 'lists link image media table code wordcount emoticons preview searchreplace',
    toolbar: 'bold italic underline strikethrough | forecolor backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | image media link | table | emoticons | searchreplace preview | code',
    menubar: false,
    license_key: 'gpl',
  };
  public maxSizesUpload = 2000000;

  public form = this.formBuilder.group({
    title: [null, [Validators.required]],
    foil: [null],
    hero: [null, [Validators.required, requiredFileType(['png', 'jpg', 'jpeg']), requiredFileSize(this.maxSizesUpload)]],
    type: [null, [Validators.required]],
    status: [null, [Validators.required]],
    state: [null, [Validators.required]],
    municipality: [null, [Validators.required]],
    city: [null, [Validators.required]],
    address: [null, [Validators.required]],
    zipcode: [null, [Validators.required]],
    area: [0, [Validators.required]],
    room: [1, [Validators.required]],
    bath: [1, [Validators.required]],
    floor: [1, [Validators.required]],
    currency: ['MXN'],
    price: [null, [Validators.required]],
    remodeled: [false, [Validators.required]],
    content: [null],
    gallery: this.formBuilder.array([])
  });

  get inputTitle() { return this.form.get('title') }
  set inputTitle(value: any) { this.inputTitle.setValue(value) }
  get inputFoil() { return this.form.get('foil') }
  set inputFoil(value: any) { this.inputFoil.setValue(value) }
  get inputHero() { return this.form.get('hero') }
  set inputHero(value: any) { this.inputHero.setValue(value) }
  get inputType() { return this.form.get('type') }
  set inputType(value: any) { this.inputType.setValue(value) }
  get inputStatus() { return this.form.get('status') }
  set inputStatus(value: any) { this.inputStatus.setValue(value) }
  get inputState() { return this.form.get('state') }
  set inputState(value: any) { this.inputState.setValue(value) }
  get inputMunicipality() { return this.form.get('municipality') }
  set inputMunicipality(value: any) { this.inputMunicipality.setValue(value) }
  get inputCity() { return this.form.get('city') }
  set inputCity(value: any) { this.inputCity.setValue(value) }
  get inputAddress() { return this.form.get('address') }
  set inputAddress(value: any) { this.inputAddress.setValue(value) }
  get inputZipcode() { return this.form.get('zipcode') }
  set inputZipcode(value: any) { this.inputZipcode.setValue(value) }
  get inputArea() { return this.form.get('area') }
  set inputArea(value: any) { this.inputArea.setValue(value) }
  get inputRoom() { return this.form.get('room') }
  set inputRoom(value: any) { this.inputRoom.setValue(value) }
  get inputBath() { return this.form.get('bath') }
  set inputBath(value: any) { this.inputBath.setValue(value) }
  get inputFloor() { return this.form.get('floor') }
  set inputFloor(value: any) { this.inputFloor.setValue(value) }
  get inputCurrency() { return this.form.get('currency') }
  set inputCurrency(value: any) { this.inputCurrency.setValue(value) }
  get inputPrice() { return this.form.get('price') }
  set inputPrice(value: any) { this.inputPrice.setValue(value) }
  get inputRemodeled() { return this.form.get('remodeled') }
  set inputRemodeled(value: any) { this.inputRemodeled.setValue(value) }
  get inputContent() { return this.form.get('content') }
  set inputContent(value: any) { this.inputContent.setValue(value) }
  get inputGallery(): FormArray { return this.form.controls["gallery"] as FormArray; }
  getImageAsIndex = (index: number) => this.inputGallery.controls[index].get('file')

  async ngOnInit() {
    this.inputMunicipality?.disable()
    this.inputAddress?.disable()

    this.catalogService.getStates().then(response => response.map((item: any) => this.states.push({ label: item, value: item })))
    this.realEstateService.getTypes().then(response => response.content.map((item: any) => this.typeProperties.push({ label: item.label, value: item.propertyTypeId })))
    this.realEstateService.getStatus().then(response => response.content.map((item: any) => this.statusProperties.push({ label: item.label, value: item.propertyStatusId })))

    this.pushImage()
  }

  changeState = async () => {
    if (this.inputState.value !== null) {
      await this.catalogService.getMunicipalities(this.inputState.value).then(response => {
        this.municipalities = []
        this.inputMunicipality = null
        this.inputAddress = null

        response.map((item: any) => this.municipalities.push({ label: item, value: item }))
      })

      this.inputMunicipality?.enable()
    } else {
      this.municipalities = []
      this.inputMunicipality = null
      this.inputAddress = null
      this.inputZipcode = null
      this.mapCenter = this.mapCenterLatLng
      this.mapZoom = 0;
      window.setTimeout(() => this.mapZoom = this.mapOptions.zoom);
      this.inputMunicipality?.disable()
      this.inputAddress?.disable()
    }
  }

  changeMunicipality = async () => {
    this.inputAddress = null

    if (this.inputMunicipality.value !== null) {
      this.inputAddress?.enable()
    } else {
      this.inputZipcode = null
      this.mapCenter = this.mapCenterLatLng
      this.mapZoom = 0;
      window.setTimeout(() => this.mapZoom = this.mapOptions.zoom);
      this.inputAddress?.disable()
    }
  }

  addressAutocomplete = async (event: AutoCompleteCompleteEvent) => {
    let filtered: any[] = []
    let state: any = {}
    let query = event.query
    this.addressSuggestions = []

    await this.catalogService.getAddress(`${this.inputMunicipality.value}, ${this.inputState.value}, México`).then(response => state = response[0])

    let address = `${query}, ${this.inputMunicipality.value}, ${this.inputState.value}, México`
    let lat = state.geometry.location.lat
    let lng = state.geometry.location.lng

    await this.catalogService.getAddressAutocomplete(address, lat, lng).then(response => filtered = response)

    if (filtered != undefined) {
      this.addressSuggestions = filtered
    }
  }

  onSelectAddress = async () => {
    this.inputAddress = this.inputAddress.value.description

    await this.catalogService.getAddress(this.inputAddress.value).then(response => {
      if (response.length >= 1) {
        this.inputZipcode = response[0].zip

        this.markerMap = response[0].geometry.location
        this.mapCenter = this.markerMap

        this.mapZoom = 0;
        window.setTimeout(() => this.mapZoom = 18);
      }
    })
  }

  addMoveMarkerMap = async (event: google.maps.MapMouseEvent) => {
    if (event.latLng !== null) {
      this.markerMap = event.latLng.toJSON()
    }
  }

  onHeroChanged = (event: any) => {
    if (event.target.files && event.target.files.length) {
      this.inputHero = event.target.files[0];
    }
  }

  pushImage = () => this.inputGallery.push(this.formBuilder.group({ file: [null, [requiredFileType(['png', 'jpg', 'jpeg']), requiredFileSize(this.maxSizesUpload)]] }))

  removeImage = (index: number) => {
    this.inputGallery.removeAt(index);

    if (this.inputGallery.value <= 0) {
      this.pushImage()
    }
  }

  onInputFileChanged = (event: any, index: number) => {
    if (event.target.files && event.target.files.length) {
      this.inputGallery.controls[index].get('file')?.setValue(event.target.files[0]);
    }
  }

  save = async () => {
    this.isSaving = true;

    const myFormData = new FormData();

    myFormData.append('state', this.inputState.value)
    myFormData.append('municipality', this.inputMunicipality.value)
    myFormData.append('zipcode', this.inputZipcode.value)
    myFormData.append('address', this.inputAddress.value)

    await this.catalogService.getAddressReverse(`${this.markerMap.lat},${this.markerMap.lng}`).then(response => {
      if (response.length >= 1) {
        myFormData.append('placeId', response[0].place_id)
      }
    })

    myFormData.append('markerMap', `${this.markerMap.lat},${this.markerMap.lng}`)
    myFormData.append('city', this.inputCity.value)
    myFormData.append('description', this.inputContent.value)
    myFormData.append('title', this.inputTitle.value)
    myFormData.append('price', this.inputPrice.value)
    myFormData.append('foil', this.inputFoil.value)
    myFormData.append('statusId', this.inputStatus.value)
    myFormData.append('typeId', this.inputType.value)
    myFormData.append('currency', this.inputCurrency.value)
    myFormData.append('area', this.inputArea.value)
    myFormData.append('room', this.inputRoom.value)
    myFormData.append('bath', this.inputBath.value)
    myFormData.append('floor', this.inputFloor.value)
    myFormData.append('remodeled', this.inputRemodeled.value)
    myFormData.append('image', this.inputHero.value)

    for (const [index, item] of this.inputGallery.value.entries()) {
      if (item.file?.size <= this.maxSizesUpload) {
        myFormData.append(`gallery_${index}`, item.file)
      }
    }

    await this.realEstateService.create(myFormData)
      .then(() => {
        this.toastService.success('Se agregó la propiedad.')
        this.router.navigate(['/realestate'])
      })
      .catch(() => this.toastService.error('Error al crear la propiedad.'))

    this.isSaving = false;
  }
}
