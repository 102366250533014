import { Routes } from '@angular/router';
import { HomePage } from './home.component';
import { authGuard } from '@guards/auth.guard';

export const routes: Routes = [
  {
    path: 'home',
    component: HomePage,
    title: 'Home Page',
    canActivate: [authGuard],
  },
];
