import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { FormArray, FormBuilder, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { BackboneComponent } from '@components/backbone/backbone.component';
import { BreadcrumbComponent } from '@components/breadcrumb/breadcrumb.component';
import { PreviewUploadImageComponent } from '@components/preview-upload-image/preview-upload-image.component';
import { ButtonModule } from 'primeng/button';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { InputTextModule } from 'primeng/inputtext';
import { DynamicPagesService } from '@services/dynamic-pages.service';
import { ToastService } from '@utils/toast.service';
import { Router } from '@angular/router';
import { DropdownModule } from 'primeng/dropdown';
import { requiredFileSize, requiredFileType } from '@utils/validators';
import { EditorComponent, TINYMCE_SCRIPT_SRC } from '@tinymce/tinymce-angular';

@Component({
  selector: 'app-dynamic-pages-create',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    BackboneComponent,
    BreadcrumbComponent,
    ReactiveFormsModule,
    InputTextModule,
    ButtonModule,
    PreviewUploadImageComponent,
    InputTextareaModule,
    DropdownModule,
    EditorComponent
  ],
  providers: [
    { provide: TINYMCE_SCRIPT_SRC, useValue: 'tinymce/tinymce.min.js' }
  ],
  templateUrl: './create.component.html',
  styleUrl: './create.component.scss'
})
export class DynamicPagesCreatePage {
  private formBuilder = inject(FormBuilder);
  private dynamicPagesService = inject(DynamicPagesService);
  private toastService = inject(ToastService);
  private router = inject(Router);

  public isLoading = false;
  public isSaving = false;
  public separatorExp: RegExp = /,| /;
  public maxSizesUpload = 2000000;
  public tinymceInit: EditorComponent['init'] = {
    plugins: 'lists link image media table code wordcount emoticons preview searchreplace',
    toolbar: 'bold italic underline strikethrough | forecolor backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | image media link | table | emoticons | searchreplace preview | code',
    menubar: false,
    license_key: 'gpl',
  };

  public formPage = this.formBuilder.group({
    title: [null, [Validators.required]],
    slug: [null],
    hero: [null, [Validators.required, requiredFileType(['png', 'jpg', 'jpeg']), requiredFileSize(this.maxSizesUpload)]],
    sections: this.formBuilder.array([])
  });

  get title() { return this.formPage.get('title') }
  set title(value: any) { this.title.setValue(value) }
  get slug() { return this.formPage.get('slug') }
  set slug(value: any) { this.slug.setValue(value) }
  get hero() { return this.formPage.get('hero') }
  set hero(value: any) { this.hero.setValue(value) }

  get sections(): FormArray { return this.formPage.controls["sections"] as FormArray; }
  sectionContent = (index: number): FormArray => { return this.sections.controls[index] as FormArray; }

  public typePageSections: any[] = [
    { value: 'input', label: 'Campo de texto.' },
    { value: 'textarea', label: 'Campo de texto grande.' },
    { value: 'editor', label: 'Editor de texto.' },
  ]

  public formAddSections: { type: string | null, name: string | null }[] = []

  onHeroChanged = (event: any) => {
    if (event.target.files && event.target.files.length) {
      this.hero = event.target.files[0];
    }
  }

  pushSection = () => {
    this.sections.push(this.formBuilder.array([]))
    this.formAddSections.push({ type: null, name: null })
  }

  removeSection = (index: number) => {
    this.sections.removeAt(index)
    this.formAddSections.splice(index, 1)
  }

  pushContent = (index: number) => {
    const section: FormArray = this.sections.controls[index] as FormArray;
    section.push(this.formBuilder.group({
      type: [this.formAddSections[index].type, Validators.required],
      name: [this.formAddSections[index].name, Validators.required],
      value: [null]
    }))

    this.formAddSections[index].type = null;
    this.formAddSections[index].name = null;
  }

  removeContent = (index: number, indexContent: number) => {
    const section: FormArray = this.sections.controls[index] as FormArray;

    section.removeAt(indexContent)
  }

  save = async () => {
    this.isSaving = true;

    const myFormData = new FormData();

    myFormData.append('title', this.title.value)
    myFormData.append('slug', this.slug.value)
    myFormData.append('hero', this.hero.value)

    myFormData.append('sections', JSON.stringify(this.sections.value))

    await this.dynamicPagesService.create(myFormData)
      .then(() => {
        this.toastService.success('Página creada con éxito.')
        this.router.navigate(['/pages'])
      })
      .catch(() => this.toastService.error('Error al crear la página.'))

    this.isSaving = false;
  }
}
