import { CurrencyPipe } from '@angular/common';
import { Component, inject } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BackboneComponent } from '@components/backbone/backbone.component';
import { BreadcrumbComponent } from '@components/breadcrumb/breadcrumb.component';
import { RealEstateService } from '@services/realestate.service';
import { Table, TableModule } from 'primeng/table';
import { TagModule } from 'primeng/tag';
import { InputTextModule } from 'primeng/inputtext';
import { ButtonModule } from 'primeng/button';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'app-realestate-list',
  standalone: true,
  imports: [
    FormsModule,
    BackboneComponent,
    BreadcrumbComponent,
    TableModule,
    CurrencyPipe,
    TagModule,
    InputTextModule,
    ButtonModule,
    RouterModule
  ],
  templateUrl: './list.component.html',
  styleUrl: './list.component.scss'
})
export class RealEstateListPage {
  private realEstateService = inject(RealEstateService);

  public searchValue: string | undefined;
  public propertiesList: any[] = [];

  async ngOnInit() {
    await this.realEstateService.getProperties().then((response) => this.propertiesList = response.content)
  }

  getSeverity(status: string) {
    switch (status) {
      case 'Vendido':
        return 'success';

      case 'Disponible':
        return 'secondary';

      case 'Remodelación':
        return 'info';

      case 'Recuperación':
        return 'warning';

      default:
        return undefined;
    }
  }

  clear(table: Table) {
    table.clear();
    this.searchValue = ''
  }
}
