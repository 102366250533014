import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService, IAccessAuth } from '@utils/auth.service';
import { ToastService } from '@utils/toast.service';
import { InputTextModule } from 'primeng/inputtext';
import { PasswordModule } from 'primeng/password';
import { ButtonModule } from 'primeng/button';

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    InputTextModule,
    PasswordModule,
    ButtonModule
  ],
  providers: [AuthService],
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss'
})
export class LoginPage {
  private formBuilder = inject(FormBuilder)
  private authService = inject(AuthService)
  private router = inject(Router)
  private toastService = inject(ToastService)

  public isLoading = false

  public form = this.formBuilder.group({
    email: [null, Validators.required],
    password: [null, Validators.required],
  });

  get inputEmail() { return this.form.get('email') }
  set inputEmail(value: any) { this.inputEmail.setValue(value) }
  get inputPassword() { return this.form.get('password') }
  set inputPassword(value: any) { this.inputPassword.setValue(value) }

  login = (): void => {
    if (this.form.valid) {
      this.isLoading = true
      let body: IAccessAuth = {
        email: `${this.inputEmail?.value}`,
        password: `${this.inputPassword?.value}`
      }

      this.authService.login(body)
        .then((response) => {
          this.authService.setAuthSession(response)
          this.toastService.success('Inicio de sesión correcto.')
          this.router.navigate(['/'])

          setTimeout(() => this.isLoading = false, 2000);
        })
        .catch((error) => {
          this.inputPassword?.setValue(null)

          error.error.data.labels_errors.forEach((item: any) => {
            if (item.label === 'email') {
              this.inputEmail?.setErrors({ 'incorrect': true })
            }

            if (item.label === 'password') {
              this.inputPassword?.setErrors({ 'incorrect': true })
            }
          });

          this.isLoading = false
        })
    }
  }
}
