import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { FormBuilder, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { BackboneComponent } from '@components/backbone/backbone.component';
import { BreadcrumbComponent } from '@components/breadcrumb/breadcrumb.component';
import { CatalogService } from '@services/catalog.service';
import { UserService } from '@services/user.service';
import { ToastService } from '@utils/toast.service';
import { InputTextModule } from 'primeng/inputtext';
import { InputMaskModule } from 'primeng/inputmask';
import { PasswordModule } from 'primeng/password';
import { DropdownModule } from 'primeng/dropdown'
import { ButtonModule } from 'primeng/button';

@Component({
  selector: 'app-user-create',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    BackboneComponent,
    BreadcrumbComponent,
    ReactiveFormsModule,
    InputTextModule,
    InputMaskModule,
    PasswordModule,
    DropdownModule,
    ButtonModule
  ],
  templateUrl: './create.component.html',
  styleUrl: './create.component.scss'
})
export class UserCreatePage {
  private formBuilder = inject(FormBuilder);
  private catalogService = inject(CatalogService);
  private userService = inject(UserService);
  private toastService = inject(ToastService);
  private router = inject(Router);

  public isLoading = false;
  public isSaving = false;

  public permissions: any = [];

  public formUser = this.formBuilder.group({
    email: [null, [Validators.required, Validators.email]],
    firstName: [null, Validators.required],
    lastName: [null, Validators.required],
    phone: [null],
    permissionId: [null, Validators.required],
    password: [null, [Validators.required, Validators.minLength(8)]],
    rpassword: [null, [Validators.required, Validators.minLength(8)]],
  });

  get email() { return this.formUser.get('email') }
  set email(value: any) { this.email?.setValue(value) }
  get firstName() { return this.formUser.get('firstName') }
  set firstName(value: any) { this.firstName?.setValue(value) }
  get lastName() { return this.formUser.get('lastName') }
  set lastName(value: any) { this.lastName?.setValue(value) }
  get phone() { return this.formUser.get('phone') }
  set phone(value: any) { this.phone?.setValue(value) }
  get permissionId() { return this.formUser.get('permissionId') }
  set permissionId(value: any) { this.permissionId?.setValue(value) }
  get password() { return this.formUser.get('password') }
  set password(value: any) { this.password?.setValue(value) }
  get rpassword() { return this.formUser.get('rpassword') }
  set rpassword(value: any) { this.rpassword?.setValue(value) }

  async ngOnInit() {
    this.isLoading = true;

    await this.catalogService.permissions()
      .then((response: any) => this.permissions = response)

    this.isLoading = false;
  }

  save = async () => {
    this.isSaving = true;

    await this.userService.create(this.formUser.value)
      .then(() => {
        this.toastService.success('Cuenta de usuario creada exitosamente.')
        this.router.navigate(['/user'])
      })
      .catch(() => this.toastService.error('Error al crear la cuenta de usuario.'))

    this.isSaving = false;
  }
}
