import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { BackboneComponent } from '@components/backbone/backbone.component';
import { BreadcrumbComponent } from '@components/breadcrumb/breadcrumb.component';
import { ShowForPermissionsDirective } from '@directives/show-for-permissions.directive';
import { DynamicPagesService } from '@services/dynamic-pages.service';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { Table, TableModule } from 'primeng/table';

@Component({
  selector: 'app-dynamic-pages-list',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    BackboneComponent,
    BreadcrumbComponent,
    TableModule,
    InputTextModule,
    ButtonModule,
    RouterModule,
    ShowForPermissionsDirective
  ],
  templateUrl: './list.component.html',
  styleUrl: './list.component.scss'
})
export class DynamicPagesListPage {
  private dynamicPagesService = inject(DynamicPagesService);

  public searchValue: string | undefined;
  public pages: any[] = [];

  async ngOnInit() {
    await this.dynamicPagesService.getList().then((response) => this.pages = response.content)
  }

  clear(table: Table) {
    table.clear();
    this.searchValue = ''
  }
}
