import { inject, Injectable } from '@angular/core';
import { HttpService } from '@utils/http.service';

@Injectable({
  providedIn: 'root'
})
export class DynamicPagesService {
  private http = inject(HttpService)

  public getList = async () => await this.http.get(`/v1/dynamic-page`)
    .then((response: any) => response.data)

  public getPage = async (page: string) => await this.http.get(`/v1/dynamic-page/${page}?by=page`)
    .then((response: any) => response.data)

  public create = async (body: any) => await this.http.post(`/v1/dynamic-page`, body)
    .then((response: any) => response)

  public update = async (page: string, body: any) => await this.http.put(`/v1/dynamic-page/${page}?by=page`, body)
    .then((response: any) => response)

  public delete = async (page: string) => await this.http.delete(`/v1/dynamic-page/${page}?by=page`)
    .then((response: any) => response.data)
}
