import { Routes } from '@angular/router';
import { routes as solviiforma } from './solviiforma/solviiforma.routes';

export const routes: Routes = [
  {
    path: 'leads',
    children: [
      ...solviiforma,
    ],
  },
];
