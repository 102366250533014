import { CurrencyPipe } from '@angular/common';
import { Component, inject } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterLink } from '@angular/router';
import { BackboneComponent } from '@components/backbone/backbone.component';
import { BreadcrumbComponent } from '@components/breadcrumb/breadcrumb.component';
import { SolviiformaService } from '@services/solviiforma.service';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { Table, TableModule } from 'primeng/table';
import { TagModule } from 'primeng/tag';

@Component({
  selector: 'app-solviiforma-list',
  standalone: true,
  imports: [
    FormsModule,
    BackboneComponent,
    BreadcrumbComponent,
    TableModule,
    ButtonModule,
    TagModule,
    CurrencyPipe,
    InputTextModule,
    RouterLink
  ],
  templateUrl: './list.component.html',
  styleUrl: './list.component.scss'
})
export class SolviiformaListPage {
  private solviiformaService = inject(SolviiformaService);

  public searchValue: string | undefined;
  public leadsList: any[] = [];

  async ngOnInit() {
    await this.solviiformaService.getList().then((response) => this.leadsList = response.content)
  }

  getSeverity(status: string) {
    switch (status) {
      case 'SI':
        return 'success';

      case 'NO':
        return 'danger';

      default:
        return undefined;
    }
  }

  clear(table: Table) {
    table.clear();
    this.searchValue = ''
  }
}
