<backbone>
  <section header-page="topbar" class="d-flex h-100 position-relative">
    <div class="w-50 d-flex justify-content-start align-items-center">
      <p class="m-0 fw-medium">Crear cuenta de usuario</p>
    </div>

    <div class="w-50 d-flex justify-content-end align-items-center gap-3">
      <breadcrumb [links]="[{title: 'Dashboard'}, {title: 'Usuarios', link: '/user'}, {title: 'Nuevo'}]" />

      <p-button styleClass="rounded-2" size="small" icon="pi pi-save" label="Crear" [loading]="isSaving"
        (onClick)="save()" [disabled]="!formUser.valid"></p-button>
    </div>
  </section>

  <section wrapper>
    <form [formGroup]="formUser" [ngClass]="{'opacity-50 pointer-events user-select-none': this.isSaving === true}">
      <div class="page-section card rounded-2 shadow-sm">
        <div class="card-header align-items-center d-flex rounded-top-2">
          <h6 class="card-title flex-grow-1 m-0">Información de la cuenta de usuario.</h6>
        </div>
        <div class="card-body">
          <div class="mb-3 row">
            <label class="col-sm-2 col-form-label d-flex align-items-center">Correo electronico</label>
            <div class="col-sm-10">
              <input formControlName="email" type="email" pInputText class="w-100 h-50px"
                placeholder="example@domain.com" />
            </div>
          </div>

          <div class="mb-3 row">
            <label class="col-sm-2 col-form-label d-flex align-items-center">Nombre(s)</label>
            <div class="col-sm-10">
              <input formControlName="firstName" type="text" pInputText class="w-100 h-50px" />
            </div>
          </div>

          <div class="mb-3 row">
            <label class="col-sm-2 col-form-label d-flex align-items-center">Apellidos</label>
            <div class="col-sm-10">
              <input formControlName="lastName" type="text" pInputText class="w-100 h-50px" />
            </div>
          </div>

          <div class="mb-3 row">
            <label class="col-sm-2 col-form-label d-flex align-items-center">Número de télefono</label>
            <div class="col-sm-10">
              <p-inputMask formControlName="phone" mask="(999) 999-9999" placeholder="(999) 999-9999"
                styleClass="w-100 h-50px"></p-inputMask>
            </div>
          </div>

          <div class="mb-3 row">
            <label class="col-sm-2 col-form-label d-flex align-items-center">Nivel de acceso</label>
            <div class="col-sm-10">
              <p-dropdown formControlName="permissionId" [options]="permissions" optionLabel="description"
                optionValue="permissionId" showClear="false" styleClass="w-100 h-50px">
                <ng-template pTemplate="selectedItem" let-selectedOption>
                  <div>{{ selectedOption.description }}</div>
                </ng-template>
                <ng-template let-item pTemplate="item">
                  <div>{{ item.description }}</div>
                </ng-template>
              </p-dropdown>
            </div>
          </div>
        </div>
      </div>

      <div class="page-section card rounded-2 shadow-sm">
        <div class="card-header align-items-center d-flex rounded-top-2">
          <h6 class="card-title flex-grow-1 m-0">Actualizar contraseña.</h6>
        </div>
        <div class="card-body">
          <div class="mb-3 row">
            <label class="col-sm-2 col-form-label d-flex align-items-center">Contraseña</label>
            <div class="col-sm-10">
              <p-password formControlName="password" styleClass="w-100" inputStyleClass="w-100 h-50px"
                [toggleMask]="true"></p-password>
            </div>
          </div>

          <div class="mb-3 row">
            <label class="col-sm-2 col-form-label d-flex align-items-center">Repetir
              contraseña</label>
            <div class="col-sm-10">
              <p-password formControlName="rpassword" styleClass="w-100" inputStyleClass="w-100 h-50px"
                [toggleMask]="true"></p-password>
            </div>
          </div>
        </div>
      </div>
    </form>
  </section>
</backbone>