import { Component } from '@angular/core';
import { TopbarComponent } from '@components/headers/topbar/topbar.component';
import { MainMenuComponent } from '@components/sidebars/main-menu/main-menu.component';

@Component({
  selector: 'backbone',
  standalone: true,
  imports: [
    TopbarComponent,
    MainMenuComponent
  ],
  templateUrl: './backbone.component.html',
  styleUrl: './backbone.component.scss'
})
export class BackboneComponent {

}
