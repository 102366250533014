import { CommonModule, CurrencyPipe } from '@angular/common';
import { Component, inject } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BackboneComponent } from '@components/backbone/backbone.component';
import { BreadcrumbComponent } from '@components/breadcrumb/breadcrumb.component';
import { Table, TableModule } from 'primeng/table';
import { TagModule } from 'primeng/tag';
import { InputTextModule } from 'primeng/inputtext';
import { ButtonModule } from 'primeng/button';
import { RouterModule } from '@angular/router';
import { UserService } from '@services/user.service';
import { CatalogService } from '@services/catalog.service';
import { ShowForPermissionsDirective } from '@directives/show-for-permissions.directive';

@Component({
  selector: 'app-user-list',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    BackboneComponent,
    BreadcrumbComponent,
    TableModule,
    CurrencyPipe,
    TagModule,
    InputTextModule,
    ButtonModule,
    RouterModule,
    ShowForPermissionsDirective
  ],
  templateUrl: './list.component.html',
  styleUrl: './list.component.scss'
})
export class UserListPage {
  private userService = inject(UserService);
  private catalogService = inject(CatalogService);

  public searchValue: string | undefined;
  public isLoading = true;
  public users: any[] = [];
  public roles: any = [];

  async ngOnInit() {
    await this.catalogService.permissions().then((response) => this.roles = response)
    this.refresh();
  }

  clear(table: Table) {
    table.clear();
    this.searchValue = ''
  }

  refresh = () => {
    this.isLoading = true;
    this.getUsers();
  }

  getUsers = async () => {
    await this.userService.getList().then((response) => this.users = response)

    this.isLoading = false
  }

  findRole = (id: number) => this.roles.find((role: any) => role.permissionId === id)
}
