<topbar />

<main-menu />

<main id="wrapper">
  <section class="topbar">
    <ng-content select="[header-page]"></ng-content>
  </section>

  <div class="container-fluid">
    <ng-content select="[wrapper]"></ng-content>
  </div>
</main>