import { Routes } from '@angular/router';
import { LoginPage } from './login.component';

export const routes: Routes = [
  {
    path: 'login',
    component: LoginPage,
    title: 'Inicia Sesión',
  },
];
