import { Routes } from '@angular/router';
import { authGuard } from '@guards/auth.guard';
import { SolviiformaListPage } from './list/list.component';
import { SolviiformaDetailPage } from './detail/detail.component';

let moduleName = 'solviiforma';

export const routes: Routes = [
  {
    path: moduleName,
    component: SolviiformaListPage,
    title: 'Lista de leads ingresados en la Solviiforma',
    canActivate: [authGuard],
  },
  {
    path: `${moduleName}/:id`,
    component: SolviiformaDetailPage,
    title: 'Lead registrado desde la Solviiforma',
    canActivate: [authGuard],
  }
];
