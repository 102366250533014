<backbone>
  <section header-page="topbar" class="d-flex h-100 position-relative">
    <div class="w-50 d-flex justify-content-start align-items-center">
      <p class="m-0 fw-medium">Viendo lead: {{this.lead?.name}}</p>
    </div>

    <div class="w-50 d-flex justify-content-end align-items-center gap-3">
      <breadcrumb
        [links]="[{title: 'Dashboard'}, {title: 'Leads Solviiforma', link: '/leads/solviiforma'}, {title: 'Detalles'}]" />
    </div>
  </section>

  <section wrapper>
    <form [formGroup]="form" [ngClass]="{'opacity-50 pointer-events-none user-select-none': this.isSaving === true}">
      <div class="card page-section rounded-2 shadow-sm">
        <div class="card-header align-items-center d-flex rounded-top-2">
          <h6 class="card-title flex-grow-1 m-0">Información de contacto.</h6>
        </div>
        <div class="card-body">
          <div class="mb-3 d-flex gap-2">
            <div class="d-flex flex-column gap-2 w-50">
              <input formControlName="name" type="text" pInputText class="w-100 h-50px" />
              <small class="ps-2">Nombre.</small>
            </div>
            <div class="d-flex flex-column gap-2 w-50">
              <p-inputMask formControlName="phone" mask="(999) 999-9999" styleClass="w-100 h-50px"></p-inputMask>
              <small class="ps-2">Número de télefono.</small>
            </div>
          </div>
          <div class="d-flex gap-2">
            <div class="d-flex flex-column gap-2 w-100">
              <input formControlName="email" type="email" pInputText class="w-100 h-50px" />
              <small class="ps-2">Correo electrónico.</small>
            </div>
          </div>
        </div>
      </div>

      <div class="card page-section rounded-2 shadow-sm">
        <div class="card-header align-items-center d-flex rounded-top-2">
          <h6 class="card-title flex-grow-1 m-0">Información de ubicación.</h6>
        </div>
        <div class="card-body">
          <div class="mb-3 d-flex gap-2">
            <div class="d-flex flex-column gap-2 w-50">
              <input formControlName="state" type="text" pInputText class="w-100 h-50px" />
              <small class="ps-2">Estado.</small>
            </div>
            <div class="d-flex flex-column gap-2 w-50">
              <input formControlName="municipality" type="text" pInputText class="w-100 h-50px" />
              <small class="ps-2">Municipio.</small>
            </div>
          </div>
          <div class="mb-3 d-flex gap-2">
            <div class="d-flex flex-column gap-2 w-100">
              <input formControlName="address" type="text" pInputText class="w-100 h-50px" />
              <small class="ps-2">Dirección.</small>
            </div>
          </div>
          <div class="d-flex gap-2">
            <div class="d-flex flex-column gap-2 w-50">
              <input formControlName="zipcode" type="text" pInputText class="w-100 h-50px" />
              <small class="ps-2">Código Postal.</small>
            </div>
            <div class="d-flex flex-column gap-2 w-50">
              <input formControlName="colony" type="text" pInputText class="w-100 h-50px" />
              <small class="ps-2">Colonia.</small>
            </div>
          </div>
        </div>
      </div>

      <div class="card page-section rounded-2 shadow-sm">
        <div class="card-header align-items-center d-flex rounded-top-2">
          <h6 class="card-title flex-grow-1 m-0">Información del inmueble.</h6>
        </div>
        <div class="card-body">
          <div class="mb-3 d-flex gap-2">
            <div class="d-flex flex-column gap-2 w-50">
              <p-dropdown formControlName="typeProperty" [options]="typeProperties" optionLabel="label"
                optionValue="value" styleClass="w-100 h-50px">
                <ng-template pTemplate="selectedItem" let-selectedOption>
                  <div>{{ selectedOption.label }}</div>
                </ng-template>
                <ng-template let-item pTemplate="item">
                  <div>{{ item.label }}</div>
                </ng-template>
              </p-dropdown>
              <small class="ps-2">Tipo de inmueble.</small>
            </div>
            <div class="d-flex flex-column gap-2 w-50">
              <input formControlName="area" type="text" pInputText class="w-100 h-50px text-center" />
              <small class="ps-2 text-center">Área en metros cuadrados.</small>
            </div>
            <div class="d-flex flex-column gap-2 w-50">
              <input formControlName="room" type="text" pInputText class="w-100 h-50px text-center" />
              <small class="ps-2 text-center">Número de recamaras.</small>
            </div>
            <div class="d-flex flex-column gap-2 w-50">
              <input formControlName="bath" type="text" pInputText class="w-100 h-50px text-center" />
              <small class="ps-2 text-center">Número de baños.</small>
            </div>
          </div>

          <div class="d-flex gap-2">
            <div class="d-flex flex-column gap-2 w-50">
              <p-inputNumber formControlName="debt" inputId="currency" inputStyleClass="w-100 h-50px"
                styleClass="w-100 h-50px" mode="currency" locale="es-MX" currency="MXN"></p-inputNumber>
              <small class="ps-2">Adeudos del inmueble.</small>
            </div>

            @if (this.debt.value > 0) {
            <div class="d-flex flex-column gap-2 w-50">
              <input formControlName="infonavit" type="text" pInputText class="w-100 h-50px" />
              <small class="ps-2">Número de crédito.</small>
            </div>
            <div class="d-flex flex-column gap-2 w-50">
              <input formControlName="cadastral" type="text" pInputText class="w-100 h-50px" />
              <small class="ps-2">Número de clave catastral.</small>
            </div>
            }

            <div class="d-flex flex-column gap-2 w-50">
              <p-inputNumber formControlName="desiredAmount" inputId="currency" inputStyleClass="w-100 h-50px"
                styleClass="w-100 h-50px" mode="currency" locale="es-MX" currency="MXN"></p-inputNumber>
              <small class="ps-2">Mónto deseado.</small>
            </div>
          </div>
        </div>
      </div>

      <div class="card page-section rounded-2 shadow-sm">
        <div class="card-header align-items-center d-flex rounded-top-2">
          <h6 class="card-title flex-grow-1 m-0">Información de la calculadora.</h6>
        </div>
        <div class="card-body">
          <div class="mb-3 d-flex gap-2">
            <div class="d-flex flex-column gap-2 w-50">
              <input formControlName="priceScore" type="text" pInputText class="w-100 h-50px text-center" />
              <small class="ps-2 text-center">Score.</small>
            </div>
            <div class="d-flex flex-column gap-2 w-50">
              <p-inputNumber formControlName="priceFinal" inputId="currency" inputStyleClass="w-100 h-50px"
                styleClass="w-100 h-50px" mode="currency" locale="es-MX" currency="MXN"></p-inputNumber>
              <small class="ps-2">Precio final sugerido.</small>
            </div>
            <div class="d-flex flex-column gap-2 w-50">
              <p-inputNumber formControlName="priceByColony" inputId="currency" inputStyleClass="w-100 h-50px"
                styleClass="w-100 h-50px" mode="currency" locale="es-MX" currency="MXN"></p-inputNumber>
              <small class="ps-2">Precio por colonia.</small>
            </div>
            <div class="d-flex flex-column gap-2 w-50">
              <p-inputNumber formControlName="priceByZipcode" inputId="currency" inputStyleClass="w-100 h-50px"
                styleClass="w-100 h-50px" mode="currency" locale="es-MX" currency="MXN"></p-inputNumber>
              <small class="ps-2">Precio por código postal.</small>
            </div>
          </div>

          <div class="mb-3 d-flex gap-2">
            <div class="d-flex flex-column gap-2 w-50">
              <input formControlName="validacionOperacionCompra" type="text" pInputText class="w-100 h-50px text-center"
                [ngClass]="{'text-bg-success border-success': this.validacionOperacionCompra.value === 'SI', 'text-bg-danger border-danger' : this.validacionOperacionCompra.value === 'NO' }" />
              <small class="ps-2 text-center">Validación de compra.</small>
            </div>
            <div class="d-flex flex-column gap-2 w-50">
              <input formControlName="tipoPrograma" type="text" pInputText class="w-100 h-50px" />
              <small class="ps-2">Típo de programa.</small>
            </div>
            <div class="d-flex flex-column gap-2 w-50">
              <input formControlName="estatusCredito" type="text" pInputText class="w-100 h-50px" />
              <small class="ps-2">Estatus de crédito.</small>
            </div>
            <div class="d-flex flex-column gap-2 w-50">
              <p-inputNumber formControlName="adeudoInfonavit" inputId="currency" inputStyleClass="w-100 h-50px"
                styleClass="w-100 h-50px" mode="currency" locale="es-MX" currency="MXN"></p-inputNumber>
              <small class="ps-2">Adeudo Infonavit.</small>
            </div>
          </div>

          <div class="mb-3 d-flex gap-2">
            <div class="d-flex flex-column gap-2 w-50">
              <p-inputNumber formControlName="costoRehabilitacion" inputId="currency" inputStyleClass="w-100 h-50px"
                styleClass="w-100 h-50px" mode="currency" locale="es-MX" currency="MXN"></p-inputNumber>
              <small class="ps-2">Costo de rehabilitación.</small>
            </div>
            <div class="d-flex flex-column gap-2 w-50">
              <p-inputNumber formControlName="pagoAdeudos" inputId="currency" inputStyleClass="w-100 h-50px"
                styleClass="w-100 h-50px" mode="currency" locale="es-MX" currency="MXN"></p-inputNumber>
              <small class="ps-2">Pago de adeudos.</small>
            </div>
            <div class="d-flex flex-column gap-2 w-50">
              <p-inputNumber formControlName="pagoComisionBroker" inputId="currency" inputStyleClass="w-100 h-50px"
                styleClass="w-100 h-50px" mode="currency" locale="es-MX" currency="MXN"></p-inputNumber>
              <small class="ps-2">Comision del broker.</small>
            </div>
          </div>

          <div class="mb-3 d-flex gap-2">
            <div class="d-flex flex-column gap-2 w-50">
              <p-inputNumber formControlName="gastosNotariales" inputId="currency" inputStyleClass="w-100 h-50px"
                styleClass="w-100 h-50px" mode="currency" locale="es-MX" currency="MXN"></p-inputNumber>
              <small class="ps-2">Gastos notariales.</small>
            </div>
            <div class="d-flex flex-column gap-2 w-50">
              <p-inputNumber formControlName="flujoRemanente" inputId="currency" inputStyleClass="w-100 h-50px"
                styleClass="w-100 h-50px" mode="currency" locale="es-MX" currency="MXN"></p-inputNumber>
              <small class="ps-2">Flujo remanente.</small>
            </div>
            <div class="d-flex flex-column gap-2 w-50">
              <p-inputNumber formControlName="feePlataforma" inputId="currency" inputStyleClass="w-100 h-50px"
                styleClass="w-100 h-50px" mode="currency" locale="es-MX" currency="MXN"></p-inputNumber>
              <small class="ps-2">Fee de la plataforma.</small>
            </div>
          </div>

          <div class="mb-3 d-flex gap-2">
            <div class="d-flex flex-column gap-2 w-50">
              <p-inputNumber formControlName="utilidadBrutaPlataforma" inputId="currency" inputStyleClass="w-100 h-50px"
                styleClass="w-100 h-50px" mode="currency" locale="es-MX" currency="MXN"></p-inputNumber>
              <small class="ps-2">Utilidad bruta de la plataforma.</small>
            </div>
            <div class="d-flex flex-column gap-2 w-50">
              <p-inputNumber formControlName="inversion" inputId="currency" inputStyleClass="w-100 h-50px"
                styleClass="w-100 h-50px" mode="currency" locale="es-MX" currency="MXN"></p-inputNumber>
              <small class="ps-2">Inversión.</small>
            </div>
            <div class="d-flex flex-column gap-2 w-50">
              <p-inputNumber formControlName="adeudoVsValor" inputId="percent" inputStyleClass="w-100 h-50px"
                styleClass="w-100 h-50px" suffix="%"></p-inputNumber>
              <small class="ps-2">Adeudo VS Valor.</small>
            </div>
          </div>

          <div class="mb-3 d-flex gap-2">
            <div class="d-flex flex-column gap-2 w-50">
              <p-inputNumber formControlName="retornoSobreInversion" inputId="percent" inputStyleClass="w-100 h-50px"
                styleClass="w-100 h-50px" suffix="%"></p-inputNumber>
              <small class="ps-2">Retorno sobre inversión.</small>
            </div>
            <div class="d-flex flex-column gap-2 w-50">
              <p-inputNumber formControlName="margen" inputId="percent" inputStyleClass="w-100 h-50px"
                styleClass="w-100 h-50px" suffix="%"></p-inputNumber>
              <small class="ps-2">Márgen.</small>
            </div>
            <div class="d-flex flex-column gap-2 w-50">
              <p-inputNumber formControlName="porcentajePagoVsPrecioVenta" inputId="percent"
                inputStyleClass="w-100 h-50px" styleClass="w-100 h-50px" suffix="%"></p-inputNumber>
              <small class="ps-2">Pago VS Precio de venta.</small>
            </div>
          </div>

          <div class="d-flex gap-2">
            <div class="d-flex flex-column gap-2 w-50">
              <p-inputNumber formControlName="porcentajeCliente" inputId="percent" inputStyleClass="w-100 h-50px"
                styleClass="w-100 h-50px" suffix="%"></p-inputNumber>
              <small class="ps-2">Porcentaje del cliente.</small>
            </div>
            <div class="d-flex flex-column gap-2 w-50">
              <p-inputNumber formControlName="pagoCliente" inputId="currency" inputStyleClass="w-100 h-50px"
                styleClass="w-100 h-50px" mode="currency" locale="es-MX" currency="MXN"></p-inputNumber>
              <small class="ps-2">Pago al cliente.</small>
            </div>
            <div class="d-flex flex-column gap-2 w-50">
              <p-inputNumber formControlName="montoLiquidoCliente" inputId="currency" inputStyleClass="w-100 h-50px"
                styleClass="w-100 h-50px" mode="currency" locale="es-MX" currency="MXN"></p-inputNumber>
              <small class="ps-2">Mónto liquido al cliente.</small>
            </div>
          </div>
        </div>
      </div>
    </form>
  </section>
</backbone>