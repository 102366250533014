import { Component, inject } from '@angular/core';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from '@utils/auth.service';
import { SessionService } from '@utils/session.service';
import { StorageService } from '@utils/storage.service';
import { ToastService } from '@utils/toast.service';
import { AvatarModule } from 'primeng/avatar';
import { BadgeModule } from 'primeng/badge';

@Component({
  selector: 'topbar',
  standalone: true,
  imports: [
    NgbDropdownModule,
    AvatarModule,
    BadgeModule
  ],
  providers: [AuthService, StorageService],
  templateUrl: './topbar.component.html',
  styleUrl: './topbar.component.scss'
})
export class TopbarComponent {
  private authService = inject(AuthService)
  private storageService = inject(StorageService)
  private toastService = inject(ToastService)
  private sessionService = inject(SessionService)

  private sidebarOpenClass = '__sidebar-open'
  public user = this.sessionService.getUser()

  constructor() {
    if (this.storageService.getItem('sidebarStatus') === null) {
      this.storageService.setItem('sidebarStatus', 'close')

      if (document.body.classList.contains(this.sidebarOpenClass)) {
        document.body.classList.remove(this.sidebarOpenClass)
      }
    } else {
      if (this.storageService.getItem('sidebarStatus') === 'open') {
        document.body.classList.add(this.sidebarOpenClass)
      } else {
        document.body.classList.remove(this.sidebarOpenClass)
      }
    }
  }

  openSideMenu = () => {
    let bodyContainsClassSidebar = document.body.classList.contains(this.sidebarOpenClass)
    let storageStatusSidebar = ''

    if (bodyContainsClassSidebar === false) {
      document.body.classList.add(this.sidebarOpenClass)
      storageStatusSidebar = 'open'
    } else {
      document.body.classList.remove(this.sidebarOpenClass)
      storageStatusSidebar = 'close'
    }

    this.storageService.setItem('sidebarStatus', storageStatusSidebar)
  }

  public logout = () => {
    this.authService.logout()
    this.toastService.success('Se ha cerrado la sesión.')
  }
}
