<section class="login d-flex flex-column align-items-center py-5">
  <div class="container">
    <form [formGroup]="form" class="bg-white rounded-1 m-auto shadow-sm py-3 mb-3" (keydown.enter)="login()"
      autocomplete="off">
      <header class="p-4 pb-0">
        <figure class="text-center mb-2">
          <img src="./assets/images/logotype-color.svg" alt="" width="100px">
        </figure>
        <h1 class="fs-5 m-0 fw-medium text-center"><small>Desbloqueo de sesión</small></h1>
      </header>
      <main class="p-4 pb-1">
        <div class="d-flex flex-column gap-3">
          <div class="d-flex flex-column">
            <input type="text" pInputText class="h-50px bg-light" [disabled]="true" [value]="this.user?.email" />
            <label for="username" class="mt-1 ps-2 opacity-75">
              <small>Correo electrónico</small>
            </label>
          </div>
          <div class="d-flex flex-column">
            <p-password formControlName="password" [feedback]="false" [toggleMask]="true" styleClass="w-100 h-50px"
              [inputStyleClass]="(inputPassword?.invalid && (inputPassword?.dirty || inputPassword?.touched) && inputPassword?.errors) ? 'w-100 h-50px bg-light border-danger' : 'w-100 h-50px bg-light'" />
            <label for="username" class="mt-1 ps-2 opacity-75"
              [ngClass]="{ 'text-danger opacity-100': inputPassword?.invalid && (inputPassword?.dirty || inputPassword?.touched) && inputPassword?.errors }">
              <small>Contraseña</small>
            </label>
          </div>
          <div class="d-flex flex-column">
            <p-button size="small" label="Desbloquear sesión" styleClass="w-100 rounded-1" [style]="{height: '45px'}"
              [loading]="isLoading" (onClick)="login()" [disabled]="!form.valid"></p-button>
            <button type="button" class="btn w-100 opacity-75" (click)="clearSession()">
              <small>Ingresar con otro usuario</small>
            </button>
          </div>
        </div>
      </main>
    </form>
    <p class="text-center fw-medium opacity-25"><small>design by codemonkey.com.mx</small></p>
  </div>
</section>