import { Routes } from '@angular/router';
import { authGuard } from '@guards/auth.guard';
import { hasRolePermissionsGuard } from '@guards/has-role-permissions.guard';
import { UserListPage } from './list/list.component';
import { UserCreatePage } from './create/create.component';
import { UserUpdatePage } from './update/update.component';

let moduleName = 'user';

export const routes: Routes = [
  {
    path: moduleName,
    component: UserListPage,
    title: 'Lista de usuarios',
    canActivate: [authGuard, hasRolePermissionsGuard(null, ['read_user'])],
  },
  {
    path: `${moduleName}/create`,
    component: UserCreatePage,
    title: 'Crear usuario',
    canActivate: [authGuard, hasRolePermissionsGuard(null, ['create_user'])],
  },
  {
    path: `${moduleName}/details/:slug`,
    component: UserUpdatePage,
    title: 'Actualizar usuario',
    canActivate: [authGuard, hasRolePermissionsGuard(null, ['read_user', 'update_user'])],
  },
];
