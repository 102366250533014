import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { FormArray, FormBuilder, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { BackboneComponent } from '@components/backbone/backbone.component';
import { BreadcrumbComponent } from '@components/breadcrumb/breadcrumb.component';
import { PreviewUploadImageComponent } from '@components/preview-upload-image/preview-upload-image.component';
import { ShowForPermissionsDirective } from '@directives/show-for-permissions.directive';
import { DynamicPagesService } from '@services/dynamic-pages.service';
import { ConfirmDialogService } from '@utils/confirm-dialog.service';
import { ToastService } from '@utils/toast.service';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { DropdownModule } from 'primeng/dropdown';
import { requiredFileSize, requiredFileType } from '@utils/validators';
import { EditorComponent, TINYMCE_SCRIPT_SRC } from '@tinymce/tinymce-angular';
import { InputTextareaModule } from 'primeng/inputtextarea';

@Component({
  selector: 'app-dynamic-pages-update',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    BackboneComponent,
    BreadcrumbComponent,
    ReactiveFormsModule,
    InputTextModule,
    ButtonModule,
    InputTextareaModule,
    PreviewUploadImageComponent,
    ShowForPermissionsDirective,
    DropdownModule,
    EditorComponent
  ],
  providers: [
    { provide: TINYMCE_SCRIPT_SRC, useValue: 'tinymce/tinymce.min.js' }
  ],
  templateUrl: './update.component.html',
  styleUrl: './update.component.scss'
})
export class DynamicPagesUpdatePage {
  private formBuilder = inject(FormBuilder);
  private activatedRoute = inject(ActivatedRoute);
  private dynamicPagesService = inject(DynamicPagesService);
  private titleService = inject(Title);
  private toastService = inject(ToastService);
  private confirmDialogService = inject(ConfirmDialogService);
  private router = inject(Router);

  public isLoading = false;
  public isSaving = false;
  public isDeleting = false;
  public separatorExp: RegExp = /,| /;
  public maxSizesUpload = 2000000;
  public tinymceInit: EditorComponent['init'] = {
    plugins: 'lists link image media table code wordcount emoticons preview searchreplace',
    toolbar: 'bold italic underline strikethrough | forecolor backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | image media link | table | emoticons | searchreplace preview | code',
    menubar: false,
    license_key: 'gpl',
  };

  public titlePage = '';
  public page: any = null;

  public formPage = this.formBuilder.group({
    title: [null, [Validators.required]],
    slug: [null],
    hero: [null, [requiredFileType(['png', 'jpg', 'jpeg']), requiredFileSize(this.maxSizesUpload)]],
    sections: this.formBuilder.array([])
  });

  get title() { return this.formPage.get('title') }
  set title(value: any) { this.title.setValue(value) }
  get slug() { return this.formPage.get('slug') }
  set slug(value: any) { this.slug.setValue(value) }
  get type() { return this.formPage.get('type') }
  set type(value: any) { this.type.setValue(value) }
  get hero() { return this.formPage.get('hero') }
  set hero(value: any) { this.hero.setValue(value) }

  get sections(): FormArray { return this.formPage.controls["sections"] as FormArray; }
  sectionContent = (index: number): FormArray => { return this.sections.controls[index] as FormArray; }

  public typePageSections: any[] = [
    { value: 'input', label: 'Campo de texto.' },
    { value: 'textarea', label: 'Campo de texto grande.' },
    { value: 'editor', label: 'Editor de texto.' },
  ]

  public formAddSections: { type: string | null, name: string | null }[] = []

  ngOnInit() {
    this.isLoading = true;

    this.activatedRoute.params.subscribe(async routeParams => {
      await this.dynamicPagesService.getPage(routeParams['id'])
        .then((response: any) => this.page = response.content)

      if (this.page.length === 0) {
        this.router.navigate(['/pages'])
        this.toastService.error('La página no existe.')
      } else {
        this.titlePage = this.page.title;
        this.titleService.setTitle('Viendo la página: ' + this.titlePage + ' - ' + this.titleService.getTitle());

        this.title = this.page.title;
        this.slug = this.page.slug;

        if (this.page.data) {
          for (const [index, item] of this.page.data.entries()) {
            this.pushSection()

            for (const item2 of item) {
              (this.sections.controls[index] as FormArray)
                .push(this.formBuilder.group({
                  type: [item2.type, Validators.required],
                  name: [item2.name, Validators.required],
                  value: [item2.value]
                }))
            }
          }
        }

        this.isLoading = false;
      }
    });
  }

  onHeroChanged = (event: any) => {
    if (event.target.files && event.target.files.length) {
      this.hero = event.target.files[0];
    }
  }

  pushSection = () => {
    this.sections.push(this.formBuilder.array([]))
    this.formAddSections.push({ type: null, name: null })
  }

  removeSection = (index: number) => {
    this.sections.removeAt(index)
    this.formAddSections.splice(index, 1)
  }

  pushContent = (index: number) => {
    const section: FormArray = this.sections.controls[index] as FormArray;
    section.push(this.formBuilder.group({
      type: [this.formAddSections[index].type, Validators.required],
      name: [this.formAddSections[index].name, Validators.required],
      value: [null]
    }))

    this.formAddSections[index].type = null;
    this.formAddSections[index].name = null;
  }

  removeContent = (index: number, indexContent: number) => {
    const section: FormArray = this.sections.controls[index] as FormArray;

    section.removeAt(indexContent)
  }

  save = async () => {
    this.isSaving = true;

    const myFormData = new FormData();

    myFormData.append('title', this.title.value)
    myFormData.append('slug', this.slug.value)
    myFormData.append('hero', this.hero.value)

    myFormData.append('sections', JSON.stringify(this.sections.value))

    await this.dynamicPagesService.update(this.page.page, myFormData)
      .then(() => this.toastService.success('Página guardada exitosamente.'))
      .catch((error: any) => this.toastService.error(error.error.data.message))

    this.isSaving = false;
  }

  delete = (event: Event) => {
    this.isDeleting = true;

    this.confirmDialogService.dialog('¿Está seguro de eliminar esta página?', 'Continuar', 'Cancelar')
      .then(() => {
        this.isLoading = true

        this.dynamicPagesService.delete(this.page.page)
          .then(() => {
            this.toastService.success('Se eliminó la página.')
            this.router.navigate(['/pages'])
          })
          .catch((error: any) => {
            this.toastService.error(error.error.data.message)
            this.isDeleting = false
            this.isLoading = false
          })
      })
      .catch(() => this.isDeleting = false)
  }
}
