<header id="topbar" class="text-bg-light border-bottom">
  <div class="container-fluid d-flex justify-content-between align-items-center h-100">
    <div class="topbar__left">
      <ul class="nav-menu">
        <li class="nav-item">
          <button type="button" class="hamburguer" (click)="openSideMenu()">
            <span></span>
            <span></span>
            <span></span>
          </button>
        </li>
      </ul>
    </div>
    <div class="topbar__right h-100">
      <ul class="nav-menu">
        <li class="nav-item">
          <div ngbDropdown class="h-100 w-100" placement="bottom-end">
            <button type="button" class="btn h-100 rounded-0 d-flex align-items-center gap-2" ngbDropdownToggle>
              <p-avatar [label]="user?.firstName?.charAt(0)" shape="circle" />
            </button>
            <div class="rounded-0" ngbDropdownMenu>
              <button class="d-flex align-items-center" ngbDropdownItem>
                <i class="bi bi-sliders fs-5 me-2"></i> Configuraciones
              </button>
              <button class="d-flex align-items-center" ngbDropdownItem>
                <i class="bi bi-headset fs-5 me-2"></i> Soporte
              </button>
              <div class="dropdown-divider"></div>
              <button class="d-flex align-items-center" ngbDropdownItem (click)="logout()">
                <i class="bi bi-box-arrow-right fs-5 me-2"></i> Cerrar sesión
              </button>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</header>