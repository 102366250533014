import { Component, inject } from '@angular/core';
import { FormBuilder, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { CatalogService } from '@services/catalog.service';
import { UserService } from '@services/user.service';
import { ToastService } from '@utils/toast.service';
import { ButtonModule } from 'primeng/button';
import { CommonModule } from '@angular/common';
import { BackboneComponent } from '@components/backbone/backbone.component';
import { BreadcrumbComponent } from '@components/breadcrumb/breadcrumb.component';
import { ShowForPermissionsDirective } from '@directives/show-for-permissions.directive';
import { InputTextModule } from 'primeng/inputtext';
import { InputMaskModule } from 'primeng/inputmask';
import { PasswordModule } from 'primeng/password';
import { DropdownModule } from 'primeng/dropdown';
import { ConfirmDialogService } from '@utils/confirm-dialog.service';

@Component({
  selector: 'app-user-update',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    BackboneComponent,
    BreadcrumbComponent,
    ReactiveFormsModule,
    ShowForPermissionsDirective,
    InputTextModule,
    InputMaskModule,
    PasswordModule,
    DropdownModule,
    ButtonModule,
  ],
  templateUrl: './update.component.html',
  styleUrl: './update.component.scss'
})
export class UserUpdatePage {
  private formBuilder = inject(FormBuilder);
  private activatedRoute = inject(ActivatedRoute);
  private userService = inject(UserService);
  private router = inject(Router);
  private toastService = inject(ToastService);
  private catalogService = inject(CatalogService);
  private confirmDialogService = inject(ConfirmDialogService);
  private titleService = inject(Title);

  public isLoading = false;
  public isSaving = false;
  public isDeleting = false;

  public username!: string;
  public user: any = null;
  public permissions: any = [];

  public formUser = this.formBuilder.group({
    email: [null, [Validators.required, Validators.email]],
    firstName: [null, Validators.required],
    lastName: [null, Validators.required],
    phone: [null],
    permissionId: [null, Validators.required],
    password: [null, Validators.minLength(8)],
    rpassword: [null, Validators.minLength(8)],
  });

  get email() { return this.formUser.get('email') }
  set email(value: any) { this.formUser.get('email')?.setValue(value) }
  get firstName() { return this.formUser.get('firstName') }
  set firstName(value: any) { this.formUser.get('firstName')?.setValue(value) }
  get lastName() { return this.formUser.get('lastName') }
  set lastName(value: any) { this.formUser.get('lastName')?.setValue(value) }
  get phone() { return this.formUser.get('phone') }
  set phone(value: any) { this.formUser.get('phone')?.setValue(value) }
  get permissionId() { return this.formUser.get('permissionId') }
  set permissionId(value: any) { this.formUser.get('permissionId')?.setValue(value) }

  ngOnInit() {
    this.isLoading = true;

    this.activatedRoute.params.subscribe(async routeParams => {
      this.username = routeParams['slug']

      this.titleService.setTitle('Viendo usuario: ' + this.username + ' ' + this.titleService.getTitle());

      this.catalogService.permissions()
        .then((response: any) => this.permissions = response)

      await this.userService.getByUsername(this.username)
        .then((response: any) => {
          if (response.content.length <= 0) {
            this.router.navigate(['/user'])
            this.toastService.error('Usuario no encontrado.')
          }

          this.user = response.content
        })

      this.email = this.user.email;
      this.firstName = this.user.firstName;
      this.lastName = this.user.lastName;
      this.phone = this.user.phone;
      this.permissionId = this.user.permissionId;

      this.isLoading = false;
    });
  }

  save = async () => {
    this.isSaving = true;

    await this.userService.save(this.username, this.formUser.value)
      .then((response) => this.toastService.success('Usuario actualizado con éxito.'))
      .catch((error: any) => this.toastService.error(error.error.data.message))

    this.isSaving = false;
  }

  delete = (event: Event) => {
    this.isDeleting = true;

    this.confirmDialogService.dialog('¿Está seguro de eliminar este usuario?', 'Continuar', 'Cancelar')
      .then(() => {
        this.userService.delete(this.username)
          .then(() => {
            this.toastService.success('Usuario eliminado con éxito.')
            this.router.navigate(['/user'])
          })
          .catch((error: any) => {
            this.toastService.error(error.error.data.message)
            this.isDeleting = false
          })
      })
      .catch(() => this.isDeleting = false)
  }
}
