import { Injectable, inject } from '@angular/core';
import { MessageService } from 'primeng/api';

/**
 * Service for displaying toast messages.
 */
@Injectable({
  providedIn: 'root'
})
export class ToastService {
  private messageService = inject(MessageService)

  /**
   * Displays a success toast message.
   *
   * @param message - The success message to display.
   */
  success = (message: any) => this.toast(message)

  /**
   * Displays an error toast message.
   *
   * @param message - The error message to display.
   */
  error = (message: any) => this.toast(message, "error")

  /**
   * Clears all toast messages.
   */
  clear = () => this.messageService.clear();

  /**
   * Displays a toast message.
   *
   * @param message - The message to be displayed in the toast.
   * @param type - The type of the toast. Defaults to "success".
   * @returns A Promise that resolves when the toast is displayed.
   */
  private toast = (message: any, type: any = "success") => {
    this.messageService.add({ severity: type, summary: (type === 'success' ? 'Correcto' : 'Error'), detail: message });
  }
}
