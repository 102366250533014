import { inject, Injectable } from '@angular/core';
import { HttpService } from '@utils/http.service';

@Injectable({
  providedIn: 'root'
})
export class SolviiformaService {
  private http = inject(HttpService)

  public getList = async () => await this.http.get(`/v1/solviiforma`, false)
    .then((response: any) => response.data)
    .catch((error: any) => error)

  public getDetails = async (id: number) => await this.http.get(`/v1/solviiforma/${id}`, false)
    .then((response: any) => response.data.content)
    .catch((error: any) => error)
}
