import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService, IAccessAuth } from '@utils/auth.service';
import { ToastService } from '@utils/toast.service';
import { InputTextModule } from 'primeng/inputtext';
import { PasswordModule } from 'primeng/password';
import { ButtonModule } from 'primeng/button';
import { SessionService } from '@utils/session.service';

@Component({
  selector: 'app-lockscreen',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    InputTextModule,
    PasswordModule,
    ButtonModule
  ],
  providers: [AuthService],
  templateUrl: './lockscreen.component.html',
  styleUrl: './lockscreen.component.scss'
})
export class LockscreenPage {
  private sessionService = inject(SessionService)
  private formBuilder = inject(FormBuilder)
  private authService = inject(AuthService)
  private router = inject(Router)
  private toastService = inject(ToastService)

  public isLoading = false
  public user = this.sessionService.getUser()

  public form = this.formBuilder.group({
    password: [null, Validators.required],
  });

  get inputPassword() { return this.form.get('password') }
  set inputPassword(value: any) { this.inputPassword.setValue(value) }

  ngOnInit() {
    if (!this.sessionService.isLoggedIn()) {
      this.router.navigate(['login'])
    }
  }

  login = (): void => {
    if (this.form.valid) {
      this.isLoading = true

      let body: IAccessAuth = {
        email: String(this.user?.email),
        password: `${this.inputPassword?.value}`
      }

      this.authService.login(body)
        .then((response) => {
          this.authService.setAuthSession(response.data)
          this.toastService.success('Sesión desbloqueada.')
          this.router.navigate(['/'])
        })
        .catch((error) => {
          this.inputPassword?.setValue(null)

          error.error.data.labels_errors.forEach((item: any) => {
            if (item.label === 'password') {
              this.inputPassword?.setErrors({ 'incorrect': true })
            }
          });

          this.isLoading = false
        })
    }
  }

  clearSession = () => {
    this.sessionService.clear()
    this.router.navigate(['login'])
  }
}
