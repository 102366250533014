import { Routes } from '@angular/router';
import { authGuard } from '@guards/auth.guard';
import { hasRolePermissionsGuard } from '@guards/has-role-permissions.guard';
import { DynamicPagesCreatePage } from './create/create.component';
import { DynamicPagesListPage } from './list/list.component';
import { DynamicPagesUpdatePage } from './update/update.component';

let moduleName = 'pages';

export const routes: Routes = [
  {
    path: moduleName,
    component: DynamicPagesListPage,
    title: 'Lista de páginas',
    canActivate: [authGuard, hasRolePermissionsGuard(null, ['read_dynamic_pages'])],
  },
  {
    path: `${moduleName}/create`,
    component: DynamicPagesCreatePage,
    title: 'Crear página',
    canActivate: [authGuard, hasRolePermissionsGuard(null, ['create_dynamic_pages'])],
  },
  {
    path: `${moduleName}/details/:id`,
    component: DynamicPagesUpdatePage,
    title: 'Actualizar página',
    canActivate: [authGuard, hasRolePermissionsGuard(null, ['read_dynamic_pages', 'update_dynamic_pages'])],
  },
];
