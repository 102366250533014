import { Injectable, inject } from '@angular/core';
import { HttpService } from '@utils/http.service';
import { UtilsService } from '@utils/utils.service';

@Injectable({
  providedIn: 'root'
})
export class CatalogService {
  private http = inject(HttpService)
  private utils = inject(UtilsService)

  public permissions = async () => await this.http.get(`/v1/catalog/permission`)
    .then((response: any) => this.utils.orderArrayBy(response.data.content, 'description'))
    .catch((error: any) => error)

  public getStates = async () => await this.http.get(`/v1/copomex/states`, false)
    .then((response: any) => response.data.sort())
    .catch((error: any) => error)

  public getMunicipalities = async (state: string) => await this.http.get(`/v1/copomex/municipalities?state=${state}`, false)
    .then((response: any) => response.data.sort())
    .catch((error: any) => error)

  public getAddress = async (address: string) => await this.http.get(`/v1/googlemapsapi/address?q=${address}`, false)
    .then((response: any) => response.data)
    .catch((error: any) => error)

  public getAddressReverse = async (latlng: string) => await this.http.get(`/v1/googlemapsapi/address/reverse?q=${latlng}`, false)
    .then((response: any) => response.data)
    .catch((error: any) => error)

  public getAddressAutocomplete = async (address: string, lat: string, lng: string) => await this.http.get(`/v1/googlemapsapi/address/autocomplete?q=${address}&lat=${lat}&lng=${lng}&country=MX`, false)
    .then((response: any) => response.data)
    .catch((error: any) => error)
}
