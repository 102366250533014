import { Component, inject } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ShowForPermissionsDirective } from '@directives/show-for-permissions.directive';
import { AuthService } from '@utils/auth.service';
import { ToastService } from '@utils/toast.service';

@Component({
  selector: 'main-menu',
  standalone: true,
  imports: [
    RouterModule,
    ShowForPermissionsDirective
  ],
  templateUrl: './main-menu.component.html',
  styleUrl: './main-menu.component.scss'
})
export class MainMenuComponent {
  private authService = inject(AuthService)
  private toastService = inject(ToastService)

  public logout = () => {
    this.authService.lockscreen()
    this.toastService.success('La pantalla se ha bloqueado.')
  }
}
