<backbone>
  <section header-page="topbar" class="d-flex h-100 position-relative">
    <div class="w-50 d-flex justify-content-start align-items-center">
      <p class="m-0 fw-medium">Lista de páginas</p>
    </div>

    <div class="w-50 d-flex justify-content-end align-items-center gap-3">
      <breadcrumb [links]="[{title: 'Dashboard'}, {title: 'Lista de páginas'}]" />

      <a *showForPermissions="['create_dynamic_pages']" class="btn btn-primary"
        routerLink="create"><small>Nuevo</small></a>
    </div>
  </section>

  <section wrapper>
    <div class="page-section card">
      <div class="card-body p-0">
        <p-table #dt1 [value]="pages" dataKey="id" [rows]="25" [showCurrentPageReport]="true"
          [rowsPerPageOptions]="[25, 50, 100]" [paginator]="true"
          currentPageReportTemplate="Mostrando {first} de {last} de un total de {totalRecords} cartas de confirmación."
          [globalFilterFields]="['foil']">
          <ng-template pTemplate="caption">
            <div class="d-flex align-items-stretch justify-content-between gap-2">
              <span class="p-input-icon-left ml-auto w-50">
                <i class="pi pi-search"></i>
                <input pInputText type="text" [(ngModel)]="searchValue" class="w-100 h-50px"
                  (input)="dt1.filterGlobal(searchValue, 'contains')" placeholder="Buscar por nombre." />
              </span>
              <p-button label="Limpiar busqueda" [outlined]="true" icon="pi pi-filter-slash" (click)="clear(dt1)"
                styleClass="h-50px" />
            </div>
          </ng-template>

          <ng-template pTemplate="header">
            <tr>
              <th scope="col">ID</th>
              <th scope="col">Página</th>
              <th scope="col">Slug</th>
              <th scope="col">Fecha de creación</th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-item>
            <tr>
              <td class="text-nowrap">{{item.dynamicPageId}}</td>
              <td class="text-nowrap">
                <a routerLink="details/{{item.page}}">{{item.title}}</a>
              </td>
              <td class="text-nowrap">
                <small><i>/{{item.slug}}</i></small>
              </td>
              <td class="text-nowrap">{{item.dateCreated | date: 'longDate' }}</td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>
  </section>
</backbone>
