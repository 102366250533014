<backbone>
  <section header-page="topbar" class="d-flex h-100 position-relative">
    <div class="w-50 d-flex justify-content-start align-items-center">
      <p class="m-0 fw-medium">Página: {{this.titlePage}}.</p>
    </div>

    <div class="w-50 d-flex justify-content-end align-items-center gap-3">
      <breadcrumb [links]="[{title: 'Dashboard'}, {title: 'Páginas', link: '/pages'}, {title: this.titlePage}]" />

      @if (this.page !== null) {
      <p-button size="small" icon="pi pi-save" label="Guardar" [loading]="isSaving" (onClick)="save()"
        [disabled]="!formPage.valid"></p-button>
      <p-button *showForPermissions="['delete_dynamic_pages']" size="small" icon="pi pi-trash" severity="danger"
        [loading]="isDeleting" (onClick)="delete($event)"></p-button>
      }
    </div>
  </section>

  <section wrapper>
    <form [formGroup]="formPage" [ngClass]="{'opacity-50 pointer-events user-select-none': this.isSaving === true}">
      <div class="row">
        <div class="col-md-8">
          <div class="card page-section rounded-2 shadow-sm">
            <div class="card-header align-items-center d-flex rounded-top-2">
              <h6 class="card-title flex-grow-1 m-0">Información principal de la página.</h6>
            </div>
            <div class="card-body">
              <div class="mb-3 d-flex flex-column gap-2">
                <input placeholder="Título de la página" formControlName="title" type="text" pInputText
                  class="w-100 h-50px" />
                <small class="ps-2">Escribe un título para tu página. <span class="text-danger">Requerido
                    *</span></small>
              </div>
              <div class="d-flex flex-column gap-2">
                <input placeholder="Slug, Ejem: mi-primer-pagina" formControlName="slug" type="text" pInputText
                  class="w-100 h-50px" />
                <small class="ps-2">Puedes escribir el slug personalizado para tu página, si esta vacío, se asignara un
                  slug según el título de la página. (Opcional)</small>
              </div>
            </div>
          </div>

          @if (this.sections.controls.length > 0){
          @for (item of this.sections.controls; track item; let index = $index) {
          <div formArrayName="sections" class="card page-section rounded-2 shadow-sm">
            <div class="card-header align-items-center d-flex rounded-top-2">
              <h6 class="card-title flex-grow-1 m-0">Bloque de página</h6>
              <p-button icon="bi bi-trash-fill" severity="danger" [text]="true" size="large" styleClass="p-0"
                (click)="removeSection(index)"></p-button>
            </div>
            <div class="card-body" [formGroupName]="index">
              <div class="mb-3 d-flex justify-content-between gap-2">
                <p-dropdown [(ngModel)]="this.formAddSections[index].type" [ngModelOptions]="{standalone: true}"
                  [options]="typePageSections" placeholder="Tipo de sección" showClear="false"
                  styleClass="w-100 h-50px">
                  <ng-template pTemplate="selectedItem" let-selectedOption>
                    <div>{{ selectedOption.label }}</div>
                  </ng-template>
                  <ng-template let-item pTemplate="item">
                    <div>{{ item.label }}</div>
                  </ng-template>
                </p-dropdown>
                <input [(ngModel)]="this.formAddSections[index].name" [ngModelOptions]="{standalone: true}" pInputText
                  placeholder="Nombre de la sección" type="text" class="w-100 h-50px" />

                <p-button icon="pi pi-plus" label="Agregar" styleClass="h-50px"
                  [disabled]="this.formAddSections[index].type === null || this.formAddSections[index].name === null"
                  (click)="pushContent(index)"></p-button>
              </div>

              @for (itemContent of sectionContent(index).controls; track itemContent; let indexContent = $index) {
              <div class="mb-3 d-flex justify-content-between gap-2" [formGroupName]="indexContent">
                @if (itemContent.get('type')?.value === 'input') {
                <div class="flex flex-column gap-2 w-100">
                  <input formControlName="value" type="text" pInputText class="w-100 h-50px" />
                  <small class="ps-2">{{itemContent.get('name')?.value}}</small>
                </div>
                }

                @if (itemContent.get('type')?.value === 'textarea') {
                <div class="flex flex-column gap-2 w-100">
                  <textarea formControlName="value" pInputTextarea class="w-100" rows="6"></textarea>
                  <small class="ps-2">{{itemContent.get('name')?.value}}</small>
                </div>
                }

                @if (itemContent.get('type')?.value === 'editor') {
                <div class="flex flex-column gap-2 w-100">
                  <editor formControlName="value" [init]="tinymceInit" />
                  <small class="ps-2">{{itemContent.get('name')?.value}}</small>
                </div>
                }

                <p-button icon="bi bi-trash-fill" severity="danger" size="large" styleClass="p-0 h-50px"
                  (click)="removeContent(index, indexContent)"></p-button>
              </div>
              }
            </div>
          </div>
          }
          }

          <div class="mb-4 border-2 border rounded-2" style="border-style: dashed !important;">
            <p-button label="{{'Nuevo Bloque' | uppercase}}" [text]="true" [plain]="true" styleClass="w-100 h-50px"
              (click)="pushSection()"></p-button>
          </div>
        </div>

        <div class="col-md-4">
          <div class="card page-section rounded-2 shadow-sm">
            <div class="card-header align-items-center d-flex rounded-top-2">
              <h6 class="card-title flex-grow-1 m-0">Imágen de portada.</h6>
            </div>
            <div class="card-body">
              <div class="d-flex flex-column gap-2">
                <preview-upload-image [image]="hero.value"
                  [imageSrc]="(this.page?.hero?.name !== null && this.page?.hero?.name !== '') ? this.page?.hero?.uri : './assets/images/media-placeholder.png'"
                  [ngClass]="{'border border-danger border-4 rounded-1': hero.value?.size > this.maxSizesUpload}">
                  <input #input (change)="onHeroChanged($event)" class="form-control h-50px" type="file"
                    style="line-height: 2.6;" accept="image/*">
                </preview-upload-image>
                @if (hero.value?.size > this.maxSizesUpload) {
                <small class="px-2 text-danger">El tamaño de la imagen no debe ser mayor a 2mb.</small>
                }
                <small class="ps-2 d-block">Formatos JPG, JPEG y PNG. Tamaño máximo 2mb. <span
                    class="text-danger">Requerido *</span></small>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </section>
</backbone>
