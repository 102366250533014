import { Routes } from '@angular/router';
import { LockscreenPage } from './lockscreen.component';

export const routes: Routes = [
  {
    path: 'lockscreen',
    component: LockscreenPage,
    title: 'Sesión bloqueada'
  },
];
